<template>
  <div class="file-btn-wrap">
    <label class="file-select">
      <div class="btn-white">
        <span>{{ spanText }}</span>
      </div>
      <input type="file" ref="fileinput" @change="handleFileChange" />
    </label>
    <div class="del-btn" v-show="show || show_delete" @click="handleFileDel">
      <img src="../assets/image/btn-del.png" alt="delete button" /> 파일삭제
    </div>
  </div>
</template>

<script>
export default {
  // props: {
  //   value: File,
  // },
  props: {
    showDel: {
      type: Boolean,
      default: false,
    },
    deleteClickHandler: {
      type: Function,
      default: () => {},
    },
    spanText: {
      type: String,
      default: "찾아보기",
    },
  },
  data() {
    return {
      file: null,
      show: false,
      initfile: this.showDel,
    };
  },
  computed: {
    show_delete: {
      get: function () {
        return this.showDel;
      },
      set: function (newValue) {
        this.initfile = newValue;
      },
    },
  },
  methods: {
    handleFileChange(e) {
      console.log("handleFileChange =========>  ", e);
      this.$emit("input", e.target.files[0]);
      // 파일 버튼 보이게
      let file = e.target.files[0];
      let typeOfFile = typeof file;
      if (typeOfFile == "object") {
        this.show = true;
      }
    },
    // eslint-disable-next-line no-unused-vars
    handleFileDel(e) {
      console.log("handleFileDel ");

      //파일 삭제버튼
      this.show = false;
      this.$refs.fileinput.value = null;
      this.$emit("input", null);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/variables";
@import "@/assets/scss/helpers/mixins";
@import "@/assets/scss/base/typhography";

.file-select > .select-button {
  padding: 1rem;
  color: white;
  background-color: #2ea169;
  border-radius: 0.3rem;
  text-align: center;
  font-weight: bold;
}

.file-select > input[type="file"] {
  display: none;
}

label {
  display: flex;
}
input[type="file"] {
  .add-file {
    background-color: #f2f2f2;
  }
}

.btn-white {
  font-size: 16px !important;
  margin-right: 10px;
}

.file-btn-wrap {
  display: flex;
  align-items: center;
  .del-btn {
    border: 1px solid red;
    @include box-center-flex;
    border: none !important;
    cursor: pointer;
    margin-right: 10px;
  }
}

.del-btn {
  display: inline-block;
}
</style>
