<template>
  <paginate
    v-model="page"
    :page-count="this.pageCount"
    :margin-pages="2"
    :page-range="10"
    firstButtonText="<<"
    prevText="<"
    nextText=">"
    lastButtonText=">>"
    :container-class="'pagination'"
    :page-class="'page-item'"
    :page-link-class="'page-link-item'"
    :prev-class="'prev-item'"
    :prev-link-class="'prev-link-item'"
    :next-class="'next-item'"
    :next-link-class="'next-link-item'"
    :break-view-class="'break-view'"
    :break-view-link-class="'break-view-link'"
    :first-last-button="true"
    :containerClass="'pagination'"
    :clickHandler="clickCallback"
    :key="initPage"
  >
  </paginate>
</template>

<script>
import Paginate from "vuejs-paginate";

export default {
  props: {
    initPage: {
      type: Number,
      default: 1,
    },
    // Total count of pages.
    pageCount: {
      type: Number,
      required: true,
    },
    pageRange: {
      type: Number,
      default: 10,
    },
    clickHandler: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      page: this.initPage,
    };
  },
  components: { Paginate },
  methods: {
    clickCallback: function (selectedPage) {
      this.clickHandler(selectedPage);
    },
  },
};
</script>

<style lang="scss" scoped></style>
