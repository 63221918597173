<template>
  <p class="search">
    <label for="history-search" class="hidden">검색</label>
    <input
      id="history-search"
      type="search"
      v-model="searchtext"
      placeholder="검색어를 입력하세요"
      v-on:keyup.enter="onButtonSearch"
    />
    <button @click="onButtonSearch">
      <img src="../assets/image/icon-search.png" alt="search icon" />
    </button>
  </p>
</template>

<script>
export default {
  name: "SearchInput",
  props: {
    searchHandler: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      searchtext: "",
    };
  },
  methods: {
    onButtonSearch() {
      if (this.searchHandler && this.searchHandler != undefined)
        this.searchHandler(this.searchtext);
      console.log(this.searchtext);
    },
  },
};
</script>

<style lang="scss" scoped></style>
